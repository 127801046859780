declare global {
    interface Window {
        heap: {
            identify: (userId: string) => void;
            addUserProperties: (properties: Record<string, any>) => void;
        };
    }
}

export { };
