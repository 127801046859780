import { Role } from '@avenews/base-types';
import { Inject, Injectable } from '@angular/core';
import { Observable, from, firstValueFrom, of } from 'rxjs';
import { SocialNetworkName, SocialNetworkRegistrationDTO, LoginDTO, OAuthContext, Credentials } from '@avenews/agt-sdk';
import { map, switchMap } from 'rxjs/operators';
import { Autocomplete, SdkService } from '@avenews/shared-lib';
import { HttpClient } from '@angular/common/http';
import IPAddress from './dtos/ip.address.dto';
import '../../../global'
import { LoginConfig } from '../../auth-config';

const credentialsKey = 'credentials';

@Injectable()
export class AuthenticationService {
  private _credentials: Credentials | null;

  constructor(private sdkService: SdkService, private httpClient: HttpClient, 
    @Inject('loginConfig') public loginConfig: LoginConfig) {
    const savedCredentials = localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      if (savedCredentials && savedCredentials !== 'undefined') {
        this._credentials = JSON.parse(savedCredentials);
        this.sdkService.setToken(this.credentials.token);
      }
    }
  }

  async login(context: LoginDTO): Promise<Credentials> {
    const { username, password } = context;

    const creds = await this.sdkService.login(username, password);
    this.setCredentials(creds);

    if (this.loginConfig?.environment?.production) {
      const ip = await firstValueFrom(this.httpClient.get<IPAddress>(`${this.loginConfig?.environment?.api_url}/user/ip`));
      this.setHeapIdentity(creds.user._id, ip.v4v6)
    }

    return creds;
  }

  oAuthLogin(context: OAuthContext, network: SocialNetworkName): Observable<Credentials> {
    return from(this.sdkService.socialLogin(network, context)).pipe(
      switchMap((user: Credentials) => {
        if (this.loginConfig?.environment?.production) {
          return this.httpClient.get<IPAddress>(`${this.loginConfig?.environment?.api_url}/user/ip`).pipe(
            map((ip) => {
              this.setCredentials(user);
              this.setHeapIdentity(user.user._id, ip.v4v6);
              return user;
            })
          );
        } else {
          this.setCredentials(user);
          return of(user);
        }
      })
    );
  }

  oAuthRegistration(socialUserInfo: SocialNetworkRegistrationDTO, network: SocialNetworkName): Observable<Credentials> {
    return from(this.sdkService.socialRegistration(socialUserInfo, network)).pipe(
      map((user: Credentials) => {
        this.setCredentials(user);
        return user;
      })
    );
  }

  forgotPassword(username: string): Observable<boolean> {
    const data = {
      email: username
    };

    return from(this.sdkService.resetPassword(data)).pipe(map(() => true));
  }

  logout(): Observable<boolean> {
    this.sdkService.setToken(this.credentials.token);
    return from(this.sdkService.logout()).pipe(
      map(() => {
        this.setCredentials();
        return true;
      })
    );
  }

  isAuthenticated(): boolean {
    return !!this.credentials;
  }

  get credentials(): Credentials | null {
    return this._credentials;
  }

  get currentUserId(): string | null {
    return this._credentials && this._credentials.user ? this._credentials.user._id : null;
  }

  get isAgribusiness(): boolean {
    return this.credentials.user.roles.includes(Role.AGRIBUSINESS);
  }

  get isAgent() {
    return this.credentials.user.policyRole.name === 'avenews-agent';
  }

  setCredentials(credentials?: Credentials) {
    this._credentials = credentials || null;
    const autocomplete = localStorage.getItem('autocomplete');
    localStorage.clear(); // removes everything, it includes the currentPage in the different lists
    if (autocomplete && autocomplete !== 'undefined') {
      localStorage.setItem('autocomplete', autocomplete);
    } else {
      const defaultAutoComplete: Autocomplete = {
        grnType: ['Goods Received', 'Purchase order'],
        saleType: ['Proforma invoice', 'Goods delivered'],

        productQuality: [],
        productNames: []
      };
      localStorage.setItem('autocomplete', JSON.stringify(defaultAutoComplete));
    }
    if (credentials?.token) {
      this.sdkService.setToken(credentials.token);
    }
    if (credentials) {
      localStorage.setItem(credentialsKey, JSON.stringify(credentials));
    }
  }

  setHeapIdentity(userId: string, ipAddress: string) {
    window.heap.identify(userId);
    window.heap.addUserProperties({ 'IP Address': ipAddress });
  }

  getCurrentUserRequest(): Promise<any> {
    return this.sdkService.getCurrentUser();
  }
}
